<template>
  <Loading v-if="loading" />
  <template v-else>
    <NotPermitted v-if="!permitted" />

    <template v-else>
      <UnderCons v-if="isUnderConstruction" />

      <template v-else>
        <div>
          <!-- Active Restaurant Select -->
          <span class="header-container">
            <p class="is-size-3 is-family-secondary has-text-info has-text-left">
              {{ $t("analytics.an") }}
            </p>
            <span
              class="active-restaurant-select"
              style="background-color: #f0ba30; padding: 1rem; border-radius: 0.5rem; z-index: 1"
            >
              <label for="active-restaurant" class="is-denim is-bold mr-3">{{ $t("active-business") }}</label>
              <select id="active-restaurant" :v-model="activeRestaurant" :onchange="changeActiveRestaurant">
                <option v-for="res of restaurants" :key="res.placeID">
                  {{ res.displayName }}
                </option>
              </select>
            </span>
          </span>

          <!-- Filter Selection -->
          <div class="filter-container">
            <div class="filter-row chip-selector">
              <div v-if="!isMultiOutlet" class="filter-row" />
              <div v-else>
                <v-chip
                  :class="{ active: groupChip === 'current' }"
                  @click="onMenuItemClick('group', 'current')"
                  style="font-weight: 900; margin-right: 10px"
                >
                  Current Unit
                </v-chip>
                <v-chip
                  :class="{ active: groupChip === 'total' }"
                  @click="onMenuItemClick('group', 'total')"
                  style="font-weight: 900"
                >
                  Total Organization
                </v-chip>
              </div>
              <div>
                <v-chip
                  :class="{ active: rangeChip === 'weekly' }"
                  @click="onMenuItemClick('dateRange', 'weekly')"
                  style="font-weight: 900; margin-right: 10px"
                >
                  Weekly
                </v-chip>
                <v-chip
                  :class="{ active: rangeChip === 'monthly' }"
                  @click="onMenuItemClick('dateRange', 'monthly')"
                  style="font-weight: 900"
                >
                  Monthly
                </v-chip>
              </div>
              <div>
                <button @click="exportAsPDF" class="button">Export as PDF</button>
              </div>
            </div>
            <div class="filter-row month-selector" v-if="rangeChip == 'monthly'">
              <div class="selected-month">
                {{ selectedMonth || "Select a Month" }}
              </div>
              <v-select
                label="Select Month"
                v-model="selectedMonth"
                :items="monthOptions"
                style="max-width: 300px"
                hide-details="auto"
              ></v-select>
            </div>
            <div class="filter-row weekly-selector" v-if="rangeChip == 'weekly'">
              <label class="" for="date-range-picker">Select a Range:</label>
              <div class="week-range-select">
                <div id="date-range-picker">
                  <span>
                    <label for="end-week-picker">Start:</label>
                    <select class="select" v-model="startWeek">
                      <option v-for="week of startWeekOptions" :key="week">{{ week }}</option>
                    </select>
                  </span>
                  <span>
                    <label for="end-week-picker">End:</label>
                    <select class="select" v-model="endWeek">
                      <option v-for="week of endWeekOptions" :key="week">{{ week }}</option>
                    </select>
                  </span>
                </div>
                <button class="button" @click="selectWeeks">Go!</button>
              </div>
            </div>
          </div>

          <!-- Data Dashboard -->
          <div class="items">
            <div v-if="!outletLoading" class="data-dashboard" style="margin-top: 15px">
              <div class="total-returns-container">
                <TotalReturns :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
              </div>

              <hr v-if="premiumData && enoughData" class="is-full mx-5" />

              <div v-if="premiumData && enoughData" class="dashboard-row">
                <div>
                  <UsersReport
                    :outlet="activeRestaurant!"
                    :weeks="selectedWeeks"
                    padding="15px 15px 0px 15px"
                    :group="includeGroup"
                  />
                  <CyclesReport
                    :outlet="activeRestaurant!"
                    :weeks="selectedWeeks"
                    padding="0px 15px 15px 15px"
                    :group="includeGroup"
                  />
                </div>
                <div v-if="isMultiOutlet" class="group-returns-container">
                  <GroupReturns :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
                </div>
                <div class="return-rates-container">
                  <ReturnRates :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
                </div>
              </div>

              <hr v-if="premiumData && enoughData" class="is-full mx-5" />

              <div v-if="premiumData && enoughData" class="dashboard-row">
                <div class="returns-breakdown-container">
                  <ReturnsBreakdown :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
                </div>
                <div class="orders-breakdown-container">
                  <OrdersBreakdown :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
                </div>
              </div>

              <div v-if="!loading && !enoughData && premiumData" class="not-enough-data-container">
                <p style="text-align: center">
                  Sorry! Not enough data - please wait until at least a month of data has been collected.
                </p>
              </div>

              <div v-if="!loading && !premiumData" class="has-text-centered">
                <h1 class="is-full is-centered is-bold is-denim">
                  {{ $t("analytics.more-details") }}
                </h1>
                <a
                  class="button is-medium is-rounded is-sunflower-back is-denim is-family-secondary"
                  href="mailto:info@friendlier.ca?CC=michelle.md@friendlier.com&Subject=Improving%20Analytics&Body=Let%20us%20know%20which%20metrics%20would%20be%20useful%20to%20your%20business%21"
                  style="margin-block: 5%; text-align: center"
                  target="_blank"
                >
                  {{ $t("analytics.click-here") }}
                </a>
              </div>

              <div class="equivalency-container">
                <Equivalencies :outlet="activeRestaurant!" :weeks="selectedWeeks" :group="includeGroup" />
              </div>
            </div>
            <div v-else>
              <Loading />
            </div>
          </div>
        </div>
      </template>
    </template>
  </template>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import type { Ref } from "vue";

import { useRouter } from "vue-router";
import html2pdf from "html2pdf.js";

import { Outlet } from "@/models";

import { getUser, useAnalytics } from "@/composables";
import { clientStore, userStore, permissionStore, collectionStore } from "@/store";
import {
  Loading,
  NotPermitted,
  UnderCons,
  TotalReturns,
  Equivalencies,
  ReturnRates,
  UsersReport,
  CyclesReport,
  GroupReturns,
  ReturnsBreakdown,
  OrdersBreakdown,
} from "@/components";
import { logError } from "@/utils";

/***** view controllers ******/
const loading: Ref<boolean> = ref(true);
const outletLoading: Ref<boolean> = ref(false);
const permitted = ref(false);
const tab = ref("total");

const router = useRouter();
const analytics = useAnalytics();

/******* user data *********/
const isUnderConstruction = ref(false);

/******* user data *********/
const { user } = getUser(); //unused

/******* restaurant lists *******/
const restaurants: Ref<Outlet[]> = ref([]);
const activeRestaurant: Ref<Outlet | undefined> = ref();

const groupChip = ref("current");
const rangeChip = ref("monthly");

const isMultiOutlet: Ref<boolean> = ref(false);

const enoughData = ref(true);
const premiumData = ref(false);

const includeGroup = ref(false);

//Dates
const datesMap: Ref<{ [key: string]: string[] }> = ref({});
const selectedMonth: Ref<string | null> = ref(null);
const selectedWeeks: Ref<string[]> = ref([]);

const avaliableWeeks: Ref<string[]> = ref([]);
const startWeek: Ref<string | undefined> = ref();
const startWeekOptions: Ref<string[]> = ref([]);
const endWeek: Ref<string | undefined> = ref();
const endWeekOptions: Ref<string[]> = ref([]);

const monthOptions: Ref<string[]> = ref([]);

onMounted(async () => {
  document.title = "Friendlier | Analytics";
  try {
    await clientStore.initialize();
    await userStore.initialize();
    await collectionStore.initialize();
    await permissionStore.initialize(userStore.accountTier);

    try {
      if (!userStore.portalApproved) {
        router.push({ path: "/business-pending" });
        return;
      }
    } catch (err: any) {
      logError(`Analytics.onMounted: Failed to check for portal approval. ${err.toString()}`);
      router.push("login");
    }

    try {
      if (permissionStore.can("viewAnalytics")) {
        permitted.value = true;
      } else {
        loading.value = false;
        return;
      }
    } catch (err: any) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    try {
      if (permissionStore.can("viewDetailedAnalytics")) {
        premiumData.value = true;
      }
    } catch (err: any) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    restaurants.value = await Outlet.getUserOutlets(userStore.user!);
    activeRestaurant.value = restaurants.value[0];

    try {
      const analyticsData = await analytics.getAnalytics();
      if (analyticsData && typeof analyticsData.isUnderConstruction !== "undefined") {
        isUnderConstruction.value = analyticsData.isUnderConstruction;
      }

      if (
        userStore.uid == "rjLsjdbJ6lZUpW3i8ey4B3enf363" ||
        userStore.uid == "W6i8CHPJRtOAAQDK35VslIwfgzP2" ||
        userStore.uid == "QcjiQr0urEVDfHXlNkR75sCwOxk2" ||
        userStore.uid == "lUg4EMjcMhVKLvRXsCRVb6COHT83" ||
        userStore.uid == "qZPsIsMn40NHwtwcFnqoUPgYu9I3"
      ) {
        isUnderConstruction.value = false;
      }
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }
    loading.value = false;
  } catch (err: any) {
    logError(`Analytics.onMounted: Failed to setup analytics page. ${err.toString()}`);
  }
});

// NOTE define need - centralize
watch(user, () => {
  if (!user.value) {
    router.push({ name: "Login" });
  }
});

/**
 * React to changes in the active restaurant and update numbers accordingly
 * @state {Boolean} loading
 * @state {Boolean} noData
 *
 */
watch(activeRestaurant, async () => {
  try {
    outletLoading.value = true;
    console.log(activeRestaurant.value!.outletGroup);
    isMultiOutlet.value = activeRestaurant.value!.outletGroup ? true : false;

    let weeks = await activeRestaurant.value!.getAnalyticEntries();
    avaliableWeeks.value = weeks;

    enoughData.value = avaliableWeeks.value.length >= 4;

    if (!startWeek.value || startWeek.value < avaliableWeeks.value[0]) {
      startWeek.value = avaliableWeeks.value[0];
    }

    if (!endWeek.value || endWeek.value > avaliableWeeks.value[avaliableWeeks.value.length - 1]) {
      endWeek.value = avaliableWeeks.value[avaliableWeeks.value.length - 1];
    }

    if (avaliableWeeks.value.length > 0) {
      updateMonthOptions();
    }

    if (rangeChip.value === "weekly") {
      selectWeeks();
    } else if (rangeChip.value === "monthly" && !selectedMonth.value) {
      selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];
    }

    outletLoading.value = false;
  } catch (err: any) {
    logError(`Analytics.watchActiveRestaurant: Failed to update for new restaurant. ${err.toString()}`);
  }
});

/**
 * Watch for changes in the startWeek value, trim the ending options to not allow inverse time range
 * @state {array} endWeekOptions
 * @state {string} startWeek
 */
watch(startWeek, () => {
  if (!endWeek.value || !startWeek.value) return;
  if (startWeek.value > endWeek.value) {
    endWeek.value = startWeek.value;
  }
  endWeekOptions.value = endWeekOptions.value.filter((e) => e >= startWeek.value!);
});

/**
 * Watch for changes in the endWeek value, trim the starting options to not allow inverse time range
 * @state {array} startWeekOptions
 * @state {string} endWeek
 */
watch(endWeek, () => {
  if (!endWeek.value || !startWeek.value) return;
  if (endWeek.value < startWeek.value) {
    endWeek.value = startWeek.value;
  }
  startWeekOptions.value = startWeekOptions.value.filter((e) => e <= endWeek.value!);
});

watch(selectedMonth, async (newVal, oldVal) => {
  if (newVal && newVal !== oldVal && rangeChip.value === "monthly") {
    selectedWeeks.value = datesMap.value[newVal];
  }
});

watch(groupChip, async (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    if (newVal === "current") {
      includeGroup.value = false;
    } else {
      includeGroup.value = true;
    }
  }
});

/**
 * Handles the click event of a menu item.
 *
 * @param {string} chipType - The type of the chip.
 * @param {string} chip - The selected chip.
 */
function onMenuItemClick(chipType: string, chip: string) {
  if (chipType === "group") {
    groupChip.value = chip;
  } else if (chipType === "dateRange") {
    rangeChip.value = chip;
    if (chip === "monthly") {
      selectedWeeks.value = datesMap.value[selectedMonth.value!];
    }
  }
}

/**
 * Changes the active restaurant based on the selected value.
 *
 * @param {Event} e - The event object.
 * @returns {void}
 */
async function changeActiveRestaurant(e: Event): Promise<void> {
  for (let res in restaurants.value) {
    let currentTarget = e.target as HTMLSelectElement;
    if (restaurants.value[res].displayName == currentTarget.value) {
      activeRestaurant.value = restaurants.value[res];
      clientStore.setActiveClientByPlaceID(restaurants.value[res].placeID);
      break;
    }
  }
}

/**
 * Selects the weeks within the specified range.
 *
 * @async
 * @function selectWeeks
 * @returns {Promise<void>} A promise that resolves when the weeks are selected.
 * @throws {Error} If there is an error selecting the weeks.
 */
function selectWeeks(): void {
  outletLoading.value = true;
  selectedWeeks.value = [];
  try {
    for (let week of avaliableWeeks.value) {
      if (week >= startWeek.value! && week <= endWeek.value!) {
        selectedWeeks.value.push(week);
      }
    }
    outletLoading.value = false;
  } catch (err: any) {
    logError(`analytics.selectWeeks: Failed to select weeks. ${err.toString()}`);
  }
}

/**
 * Updates the month options for analytics.
 *
 * @throws {Error} If there is an error while updating the month options.
 */
function updateMonthOptions() {
  try {
    datesMap.value = {};
    avaliableWeeks.value.sort();

    startWeekOptions.value = avaliableWeeks.value;
    endWeekOptions.value = avaliableWeeks.value;

    avaliableWeeks.value.forEach((dateStr) => {
      const date = new Date(dateStr);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const monthYearKey = `${date.toLocaleString("default", {
        month: "long",
      })} ${year}`;

      if (year > 2023 || (year === 2023 && monthIndex >= 9)) {
        if (!(monthYearKey in datesMap.value)) {
          datesMap.value[monthYearKey] = [];
        }

        if (day > 3) {
          datesMap.value[monthYearKey].push(dateStr);
        }
      }
    });

    for (const [_, items] of Object.entries(datesMap.value)) {
      if (items.length < 1) return;
      const lastDate = new Date(items[items.length - 1]);
      const nextMonthFirstDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 1);

      for (let i = 0; i < 4; i++) {
        let nextDate = new Date(nextMonthFirstDate);
        nextDate.setDate(nextDate.getDate() + i);
        const nextDateStr = nextDate.toISOString().split("T")[0];

        if (avaliableWeeks.value.includes(nextDateStr)) {
          items.push(nextDateStr);
        }
      }
    }

    const monthYearPairs: any[] = Array.from(Object.entries(datesMap.value));

    const sortedValidMonths = monthYearPairs
      .filter(([_, dates]) => dates.length >= 1)
      .sort((a: any[], b: any[]) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
      .map(([monthYear, _]) => monthYear);

    monthOptions.value = sortedValidMonths;

    if (monthOptions.value.length > 0 && rangeChip.value != "monthly")
      selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];

    if (rangeChip.value === "monthly" && selectedMonth.value) {
      let flag = false;
      for (let key of monthOptions.value) if (key === selectedMonth.value) flag = true;
      if (!flag) selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];
    }
  } catch (err: any) {
    logError(`analytics.updateMonthOptions: Failed to update month options. ${err.toString()}`);
  }
}

const exportAsPDF = async () => {
  const element: HTMLElement | null = document.querySelector(".items");
  const itemElement: HTMLElement | null = document.querySelector(".item");
  const premiumDataElement: HTMLElement | null = document.querySelector(".premium-data");

  if (itemElement) {
    itemElement.style.display = "none";
  }

  let originalMarginBottom: string;
  if (premiumDataElement) {
    originalMarginBottom = premiumDataElement.style.marginBottom;
    premiumDataElement.style.marginBottom = "80px";
  }

  let options = {
    margin: [0, 15, 10, 15],
    filename: "analytics-export.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 3 },
    jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
  };

  await html2pdf(element, options);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.total-returns-container {
  display: flex;
  width: 100%;
  padding: 0px 15px 0px 15px;
  justify-content: space-between;
}

.equivalency-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px 0px 15px;
  margin: 25px 0px 25px 0px;
}

.return-rates-container {
  width: 215px;
}

.group-returns-container {
  min-width: 368px;
  height: 300px;
}

.returns-breakdown-container {
  width: 50%;
  // height: 350px;
}

.orders-breakdown-container {
  width: 50%;
  padding: 20px;
}

.not-enough-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.dashboard-row {
  width: 100%;
  padding: 0px 15px 0px 15px;
  display: flex;
  justify-content: space-around;
  margin: 15px 0px 15px 0px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.filter-container {
  display: flex;
  flex-direction: column;
  margin: 0px 15px 0px 15px;

  .filter-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > label {
      font-size: 32px;
      font-family: "GibsonBold", serif !important;
      font-weight: 900;
    }

    .week-range-select {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      #date-range-picker {
        margin-right: 15px;

        span {
          margin: 5px;
          padding: 7px 12px;
          display: inline-flex;
          align-items: center;

          label {
            font-weight: bold;
            margin-right: 5px;
          }

          .select {
            padding: 7px 12px;
            border-radius: 6px;
            border: 1px solid $stormCloud;
          }
        }
      }

      .button {
        min-width: 75px;
        background-color: $ocean;
        padding: 7px 12px;
        border-radius: 6px;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .total-returns-container {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}

.v-chip.active {
  background-color: #032673;
  color: white;
}

.v-chip.hover {
  background-color: #032673;
  color: white;
}

.selected-month {
  font-size: 32px;
  font-family: "GibsonBold", serif !important;
  font-weight: 900;
  padding-top: 5px;
}
</style>
